@mixin animation($name, $duration) {
  animation-name: $name;
  animation-duration: $duration;
  //@include animation(circleShow, 0.7s);
}

@keyframes hoverOpeningFirstLine {
  25% {
    transform: rotate(-24deg);
    top: CALC(50% - #{$px14});
    @include pxInRem(height, 12px);
    @include pxInRem(left, 11px);
  }
  50% {
    transform: rotate(-43deg);
    top: CALC(50% - #{$px16});
    @include pxInRem(height, 16px);
    @include pxInRem(left, 7px);
  }
  75% {
    transform: rotate(-37deg);
    top: CALC(50% - #{$px13});
    @include pxInRem(height, 16px);
    @include pxInRem(left, 10px);
  }
  100% {
    transform: rotate(-45deg);
    top: CALC(50% - #{$px15});
    @include pxInRem(height, 16px);
    @include pxInRem(left, 7px);
  }
}
@keyframes hoverOpeningSecondLine {
  25% {
    transform: rotate(24deg);
    bottom: CALC(50% - #{$px14});
    @include pxInRem(height, 12px);
    @include pxInRem(left, 11px);
  }
  50% {
    transform: rotate(43deg);
    bottom: CALC(50% - #{$px16});
    @include pxInRem(height, 16px);
    @include pxInRem(left, 7px);
  }
  75% {
    transform: rotate(37deg);
    bottom: CALC(50% - #{$px13});
    @include pxInRem(height, 16px);
    @include pxInRem(left, 10px);
  }
  100% {
    transform: rotate(45deg);
    bottom: CALC(50% - #{$px15});
    @include pxInRem(height, 16px);
    @include pxInRem(left, 7px);
  }
}
@keyframes hoverOpeningCircle {
  25% {
    @include pxInRem(margin-left, 7px);
  }

  50% {
    @include pxInRem(margin-left, 1px);
  }
  75% {
    @include pxInRem(margin-left, 3px);
  }
  100% {
    @include pxInRem(margin-left, 0px);
  }
}
@keyframes hoverClosingFirstLine {
  25% {
    transform: rotate(24deg);
    top: CALC(50% - #{$px12});
    @include pxInRem(height, 13px);
    @include pxInRem(left, 4px);
  }
  50% {
    transform: rotate(43deg);
    top: CALC(50% - #{$px13});
    @include pxInRem(height, 16px);
    @include pxInRem(left, 7px);
  }
  75% {
    transform: rotate(37deg);
    top: CALC(50% - #{$px15});
    @include pxInRem(height, 16px);
    @include pxInRem(left, 7px);
  }
  100% {
    transform: rotate(45deg);
    top: CALC(50% - #{$px13});
    @include pxInRem(height, 16px);
    @include pxInRem(left, 7px);
  }
}
@keyframes hoverClosingSecondLine {
  25% {
    transform: rotate(-24deg);
    bottom: CALC(50% -#{$px12});
    @include pxInRem(height, 13px);
    @include pxInRem(left, 4px);
  }
  50% {
    transform: rotate(-43deg);
    bottom: CALC(50% - #{$px13});
    @include pxInRem(height, 16px);
    @include pxInRem(left, 7px);
  }
  75% {
    transform: rotate(-37deg);
    bottom: CALC(50% - #{$px15});
    @include pxInRem(height, 16px);
    @include pxInRem(left, 7px);
  }
  100% {
    transform: rotate(-45deg);
    bottom: CALC(50% - #{$px13});
    @include pxInRem(height, 16px);
    @include pxInRem(left, 7px);
  }
}
@keyframes hoverClosingCircle {
  25% {
    @include pxInRem(margin-left, 5px);
  }

  50% {
    @include pxInRem(margin-left, 11px);
  }
  75% {
    @include pxInRem(margin-left, 9px);
  }
  100% {
    @include pxInRem(margin-left, 12px);
  }
}
@keyframes clicClosingFirstLine {
  100% {
    transform: rotate(45deg);
    top: CALC(50% - #{$px13});
  }
}
@keyframes clicClosingSecondLine {
  100% {
    transform: rotate(-45deg);
    bottom: CALC(50% - #{$px13});
  }
}
@keyframes clicOpeningFirstLine {
  100% {
    transform: rotate(-45deg);
    top: CALC(50% - #{$px15});
  }
}
@keyframes clicOpeningSecondLine {
  100% {
    transform: rotate(45deg);
    bottom: CALC(50% - #{$px15});
  }
}
//Déplacement des cercles à l'ouverture du menu
@keyframes circleOpening {
  100% {
    @include pxInRem(margin-left, 12px);
  }
}
@keyframes circleClosing {
  100% {
    @include pxInRem(margin-left, 0px);
  }
}
@keyframes loadingBar {
  100% {
    width: 99%;
  }
}
@keyframes dot {
  50% {
    background-color: $secondary-color;
    transform: scale(1.5);
  }
}
