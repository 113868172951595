// colors

$background-color: #29353f;
$background-dark: #202931;
$background-light: #31404d;

$primary-color: #ffd14c;
$secondary-color: #f3a106;
$third-color: white;

$clic-color: #e62254;

$code-color1: #f991f6;
$code-color2: #5eb4fd;
$code-color3: #84fff7;
