@use "variables" as *;
@use "font" as *;
@import "variableRem";
@import "mixinMq";
@import "plugin";
@import "loader";
@import "menu";
@import "app";
@import "sectionHome";
@import "sectionAbout";
@import "sectionSkills";
@import "sectionWork";
@import "sectionContact";

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  background-color: $background-color;
}
.one-section {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  @media (max-width: 576px) {
    @include pxInRem(padding-top, 85px);
  }
  .section-container {
    @include pxInRem(width, 1140px);
    @include pxInRem(padding-left, 75px);
    @include pxInRem(padding-right, 75px);
    @media (max-width: 992px) {
      @include pxInRem(width, 960px);
    }
    @media (max-width: 768px) {
      @include pxInRem(width, 720px);
    }
    @media (max-width: 576px) {
      @include pxInRem(width, 540px);
      @include pxInRem(padding, 15px);
    }
  }
}
.balise {
  font-family: $code-font;
  color: $third-color;
  font-size: $balise-size;
  font-weight: 200;
  margin: 0;
  &.section-name {
    @include pxInRem(padding-bottom, 15px);
  }
  .balise-name {
    color: $primary-color;
  }
  .balise-class {
    color: $clic-color;
  }
  .balise-class-name {
    color: $code-color2;
  }
  &.body {
    padding-top: 0;
    opacity: 0.5;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .opening {
    @include pxInRem(padding-right, 15px);
  }
  &:not(.body) {
    @include responsive(margin-left, 50px, 40px, 25px);
  }
  &.closing {
    margin-left: 0;
  }
  &.section-h {
    @include responsive(margin-left, 100px, 80px, 50px);
    align-items: baseline;
    @media (max-width: 768px) {
      flex-direction: column;
      > div {
        @include pxInRem(padding-bottom, 15px);
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}
button {
  @include pxInRem(border-radius, 100px);
  border: none;
  box-shadow: inset 0 0 0 0px $secondary-color;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  overflow: hidden;
  transition: box-shadow 0.5s linear 0.2s;
  &:disabled {
    color: rgb(107, 105, 105);
    background-color: rgb(146, 144, 144);
    box-shadow: inset 0 0 0 2px rgb(146, 144, 144);
    &::after,
    &::before {
      background-color: rgb(146, 144, 144);
      box-shadow: inset 0 0 0 2px rgb(146, 144, 144);
      width: 100%;
    }
    &:hover {
      &::after,
      &::before {
        background-color: rgb(146, 144, 144);
        box-shadow: inset 0 0 0 2px rgb(146, 144, 144);
        width: 100%;
      }
    }
  }
  &::after {
    content: "";
    background-color: $clic-color;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: width 0.5s;
    z-index: -1;
    box-shadow: 0 0 0 2px $clic-color;
    @include pxInRem(border-radius, 100px);
  }
  &::before {
    content: "";
    background-color: $background-color;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: width 0.5s;
    transition-delay: 0.15s;
    z-index: -1;
    @include pxInRem(border-radius, 100px);
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: inset 0 0 0 2px $secondary-color;
    &::after,
    &::before {
      width: 0;
    }
    &::after,
    &::before {
      box-shadow: none;
    }
  }
  a {
    text-decoration: none;
    color: $third-color;
    font-size: $content-size;
    font-family: $content-font;
    display: flex;
    @include responsive(padding, 15px, 11px, 7px);
    @include responsive(padding-left, 30px, 22px, 15px);
    @include responsive(padding-right, 30px, 22px, 15px);
  }
}
.big-title {
  color: $secondary-color;
  font-family: $title-font;

  font-weight: 700;
  @include responsive(font-size, 54px, 45px, 35px);
  @include pxInRem(letter-spacing, 1px);
  @include pxInRem(padding-right, 15px);
  @include pxInRem(padding-left, 15px);
  @media (max-width: 768px) {
    @include pxInRem(padding-left, 25px);
  }
}
.bold {
  font-weight: 700;
}

@media screen and (max-width: 576px) {
  [data-aos],
  .aos-animate {
    transition-delay: 0s !important;
    transition-duration: 0s !important;
    opacity: 1 !important;
    transform: none !important;
  }
}
[data-aos],
.aos-animate {
  &.body {
    opacity: 0.5 !important;
  }
}
footer {
  height: $px40;
  margin: auto;
  background-color: $secondary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
    color: $third-color;
    font-family: $content-font;
    font-size: $px10;
  }
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $background-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $secondary-color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}
